import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';

import { disableScroll, enableScroll } from '@/helpers/behaviours';
import Portal from '@/components/Portal';
import { Close } from '@/components/Icons';
import { ModalTypes } from './types';

import styles from './BaseModal.module.scss';

const containerVariants = {
  initial: {
    top: 'calc(50% - 50px)',
    translateX: '-50%',
    translateY: '-50%',
    scale: 0.5,
    transition: { type: 'spring', duration: '0.3' },
  },
  isOpen: {
    top: '50%',
    scale: 1.0,
    translateX: '-50%',
    translateY: '-50%',
    transition: { type: 'spring', duration: '0.3' },
  },
  exit: {
    top: 'calc(50% - 50px)',
    translateX: '-50%',
    translateY: '-50%',
    scale: 0.5,
    transition: { type: 'spring', duration: '0.3' },
  },
};

const modalVariants = {
  initial: { opacity: 0, transition: { type: 'spring', duration: '0.3' } },
  isOpen: { opacity: 1, transition: { type: 'spring', duration: '0.3' } },
  exit: { opacity: 0, transition: { type: 'spring', duration: '0.3' } },
};

const BaseModal = ({
  isOpen,
  title,
  needScollbar = true,
  children,
  closeModal,
  shouldCloseOnOverlayClick = true,
  showCloseButton = true,
  modalType = ModalTypes.Wide,
}) => {
  const [mountDownTarget, setMountDownTarget] = useState(null);

  // Reference https://github.com/mui-org/material-ui/blob/c0287df54346221af06a5fea860a9d2e58e0f2d1/packages/material-ui/src/Dialog/Dialog.js#L143
  const handleOverlayClick = (e) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    // Make sure the event starts and ends on the same DOM element.
    if (mountDownTarget !== e.target) {
      return;
    }

    setMountDownTarget(null);

    if (closeModal && shouldCloseOnOverlayClick) {
      closeModal();
    }
  };

  const handleOverlayMouseDown = (e) => {
    setMountDownTarget(e.target);
  };

  useEffect(() => {
    if (isOpen) {
      disableScroll();
    } else {
      enableScroll();
    }

    return () => {
      enableScroll();
    };
  }, [isOpen]);

  return (
    <Portal
      mount={true}
      portalDataName={'modal-provider-gate'}
      aria-label="ModalProvider"
      aria-labelledby="modal-provider"
      role="dialog"
      aria-modal={true}
    >
      <AnimatePresence>
        {isOpen ? (
          <motion.div
            className={styles.overlay}
            initial={'initial'}
            animate={'isOpen'}
            exit={'exit'}
            variants={modalVariants}
            onMouseDown={handleOverlayMouseDown}
            onClick={handleOverlayClick}
            layout
          >
            <motion.div
              className={clsx(styles.container, [styles[modalType]])}
              initial={'initial'}
              animate={'isOpen'}
              exit={'exit'}
              variants={containerVariants}
            >
              {title ? <div className={styles.title}>{title}</div> : null}
              {needScollbar ? (
                <PerfectScrollbar options={{ wheelPropagation: false }}>
                  <div className={clsx(styles.content, { [styles.contentNoTitle]: !title })}>
                    {children}
                  </div>
                </PerfectScrollbar>
              ) : (
                <div className={clsx(styles.content, { [styles.contentNoTitle]: !title })}>
                  {children}
                </div>
              )}
              {showCloseButton ? <Close className={styles.btnClose} onClick={closeModal} /> : null}
            </motion.div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </Portal>
  );
};

export * from './types';
export default BaseModal;
