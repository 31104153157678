import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={66}
    height={66}
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M48.296 59.29a2.751 2.751 0 0 1-1.279-.319L33 51.601l-14.017 7.37a2.751 2.751 0 0 1-3.987-2.896l2.676-15.609L6.33 29.411a2.75 2.75 0 0 1 1.523-4.688l15.672-2.277 7.01-14.201a2.86 2.86 0 0 1 4.931 0l7.007 14.2 15.672 2.278a2.75 2.75 0 0 1 1.524 4.688L48.329 40.466l2.678 15.61a2.75 2.75 0 0 1-2.709 3.214h-.002Z"
      fill="#FD4F7A"
    />
  </svg>
);

export default SvgComponent;
