import React, { useCallback } from 'react';

//import ZoomIn from '@/components/ZoomIn';
import clsx from 'clsx';
import styles from './Roadmap.module.scss';

const roadmapItems = [
  {
    id: 'phase1',
    title: 'PHASE 1',
    items: [
      {
        id: 'phase11',
        type: 'boxTLeft',
        title: 'Smart Ticketing',
        text:
          'Each Owlgo Smart Ticket is registered on a blockchain. Smart Tickets ' +
          'are linked to a unique mobile number, and have dynamic QR ' +
          'codes - which guarantee authenticity and reduce the risk of scams. ' +
          'To prevent scalping, each resale transaction is executed on a ' +
          'smart contract. By setting the maximum resale value of each ' +
          'ticket, we prevent resellers from over-profiting',
        image: "/img/OWLGO_HEROIMAGE_PHONE.png",
      },
      {
        id: 'phase12',
        type: 'boxTRight',
        title: 'Payment Method',
        text:
          'Our Smart Tickets can be purchased with credit cards, with ' +
          'payments processed on Stripe. Buyers do not need to connect to a ' +
          'cryptocurrency wallet or have prior understanding of blockchains ' +
          'or cryptocurrencies to make a purchase.',
        image: '/img/OWLGO_HEROIMAGE_CREDITCARD.png',
      },
    ],
  },
  {
    id: 'phase2',
    title: 'PHASE 2',
    items: [
      {
        id: 'phase21',
        type: 'boxTLeft',
        title: 'NFTs Utility',
        text:
          'With NFTs, our Smart Tickets become collectibles. Holders will ' +
          'be entitled to exclusive deals and given early access to ' +
          'future events. Loyal holders will be rewarded with special ' +
          'perks or VIP experiences for frequent or continued engagement ' +
          'with Owlgo.',
        image: '/img//OWLGO_HEROIMAGE_NFT.png',
      },
      {
        id: 'phase22',
        type: 'boxTRight',
        title: 'Community building',
        text:
          'We aim to build a loyal and supportive community made up of ' +
          'members who are passionate about art, music, sport and other ' +
          'leisure activities. To keep our community engaged, we will ' +
          'organise virtual gatherings and physical events',
        image: '/img/OWLGO_HEROIMAGE_COMMUNITY.png',
      },
    ],
  },
  {
    id: 'phase3',
    title: 'PHASE 3',
    items: [
      {
        id: 'phase31',
        type: 'boxphase3',
        title: 'Tokenomics',
        text:
          'In the final phase of our development, with the help of investors’ ' +
          'funding and the Owlgo community, we aim to mint Owlgo coins on a ' +
          'public blockchain, with the ultimate aim of listing on a ' +
          'cryptocurrency exchange. Anyone - not just the Owlgo community - ' +
          'will be able to trade these coins as well as use them to purchase ' +
          'Owlgo Smart Tickets.',
        subitems: [
          {
            id: 'subitem1',
            title: 'Staking',
            icon: '/img/icon/mdi_hand-coin.png',
          },
          {
            id: 'subitem2',
            title: 'Token allocation',
            icon: '/img/icon/mingcute_copper-coin-fill.png',
          },
          {
            id: 'subitem3',
            title: (
              <>
                Yields/
                <br />
                Liquidity pools
              </>
            ),
            icon: '/img/icon/clarity_coin-bag-solid.png',
          },
          {
            id: 'subitem4',
            title: (
              <>
                Limited/
                <br />
                Unlimited supply
              </>
            ),
            icon: '/img/icon/game-icons_coins-pile.png',
          },
          {
            id: 'subitem5',
            title: (
              <>
                Token
                <br />
                burns
              </>
            ),
            icon: '/img/icon/mdi_hand-coin.png',
          },
          {
            id: 'subitem6',
            title: (
              <>
                Community
                <br />
                rewards
              </>
            ),
            icon: '/img/icon/mingcute_copper-coin-fill.png',
          },
          {
            id: 'subitem7',
            title: (
              <>
                Community
                <br />
                marketing fund
              </>
            ),
            icon: '/img/icon/clarity_coin-bag-solid.png',
          },
          {
            id: 'subitem8',
            title: (
              <>
                Team, Investors
                <br />& Advisors
              </>
            ),
            icon: '/img/icon/game-icons_coins-pile.png',
          },
        ],
      },
    ],
  },
];

const Roadmap = () => {
  const renderPhaseBoxTLeft = useCallback((item) => {
    return (
      <div key={item.id} className={styles.phase_box}>
        <div className={styles.phase_box_col_text_left}>
          <div className={styles.text_box}>
            <div className={styles.text_box_title}>{item.title}</div>
            <div className={styles.text_box_text}>{item.text}</div>
          </div>
        </div>
        <div className={styles.phase_box_col_image_right}>
          <img alt="" src={item.image} />
        </div>
      </div>
    );
  }, []);

  const renderPhaseBoxTRight = useCallback((item) => {
    return (
      <div key={item.id} className={styles.phase_box}>
        <div className={styles.phase_box_col_image_left}>
          <img alt="" src={item.image} />
        </div>
        <div className={styles.phase_box_col_text_right}>
          <div className={styles.text_box}>
            <div className={styles.text_box_title}>{item.title}</div>
            <div className={styles.text_box_text}>{item.text}</div>
          </div>
        </div>
      </div>
    );
  }, []);

  const renderPhase3Box = useCallback((item) => {
    return (
      <div key={item.id}>
        <div className={styles.text_box_center}>{item.text}</div>
        <div className={styles.phase_title}>{item.title}</div>
        <div className={styles.tokenomics_row}>
          {item.subitems.map((subitem) => {
            return (
              <div key={subitem.id} className={styles.tokenomics_item}>
                <div className={styles.tokenomics_icon}>
                  <img alt="" src={subitem.icon} />
                </div>
                <div className={styles.tokenomics_item_title}>{subitem.title}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, []);

  return (
    <div className={clsx(styles.roadmap_container)}>
      <div className={styles.roadmap_inner}>
        <div className={styles.centerHeadline}>
          <h2 className={styles.headline}>Future️ Roadmap</h2>
        </div>
        {roadmapItems.map((item) => {
          return (
            <div key={item.id} className={styles.phase_block}>
              <div className={styles.phase_title}>{item.title}</div>
              {item.items.map((subitem) => {
                if (subitem.type === 'boxTLeft') {
                  return renderPhaseBoxTLeft(subitem);
                }
                if (subitem.type === 'boxTRight') {
                  return renderPhaseBoxTRight(subitem);
                }
                if (subitem.type === 'boxphase3') {
                  return renderPhase3Box(subitem);
                }
                return renderPhaseBoxTLeft(subitem);
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Roadmap;
