import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={66}
    height={66}
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="b1"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={6}
      y={3}
      width={53}
      height={61}
    >
      <path
        d="M22 33c7.594 0 13.75-6.156 13.75-13.75S29.594 5.5 22 5.5 8.25 11.656 8.25 19.25 14.406 33 22 33Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={4}
        strokeLinejoin="round"
      />
      <path
        d="M35.75 20.903c.019.02 6.494 8.162 19.423 24.425a1.376 1.376 0 0 1-.085 1.851l-5.616 5.616a1.375 1.375 0 0 1-1.85.085L24.504 33m11.89.646 3.89 3.89"
        stroke="#fff"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.375 60.618c2.636-3.435 5.84-5.152 9.611-5.152 5.66 0 12.338 7.109 18.035 6.131 5.698-.979 7.328-6.597 3.822-9.707"
        stroke="#fff"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#b1)">
      <path d="M0 0h66v66H0V0Z" fill="#FD4F7A" />
    </g>
  </svg>
);

export default SvgComponent;
