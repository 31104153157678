import React from 'react';
import styles from './Home.module.scss';
//import ExampleSection from '@/components/ExampleSection';
import Hero from '@/components/Hero';
import Platform from '@/components/Platform';
import Experience from '@/components/Experience';
import Roadmap from '@/components/Roadmap';
import Contact from '@/components/Contact';

function Home() {
  return (
    <div className={styles.page}>
      <Hero id={'hero'} />
      <Platform id={'platform'} />
      <Experience id={'experience'} />
      <Roadmap id={'roadmap'} />
      <Contact id={'contact'} />
    </div>
  );
}

export default Home;
