import { get, set } from '../deepGetSet';

const isEmpty = (value) => typeof value === 'undefined' || value === null || value === '';
const join = (rules) => (value, data) =>
  rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];

export function isValidEmail(msg = 'Invalid Email Address') {
  return (value) => {
    const re =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    //source https://www.w3resource.com/javascript/form/email-validation.php
    //const re= /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if (!isEmpty(value) && !re.test(value)) {
      return msg;
    }
    return false;
  };
}

export function required(msg = 'This field is mandatory') {
  return (value) => {
    if (isEmpty(value) || false === value) {
      return msg;
    }
    return false;
  };
}

export function arrayMinLength(min, msg) {
  return (value) => {
    if (isEmpty(value) || value.length < min) {
      return { _error: msg };
    }
    return false;
  };
}

export function arrayValidator(validator) {
  return (value) => {
    // // console.log('arrayValidator', value, validator)
    if (value && Array.isArray(value)) {
      return value.map((arrayValue) => validator(arrayValue));
    }
    return false;
  };
}

export function match(field, msg) {
  return (value, data) => {
    if (data) {
      if (value !== get(data, field)) {
        return msg;
      }
      return false;
    }
    return false;
  };
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(get(data, key), data);
      if (error) {
        set(errors, key, error);
      }
    });
    return errors;
  };
}

export const minLength = (length, fieldName) => {
  return (value) => {
    if (isEmpty(value)) {
      return false;
    }
    if (value.toString().length < length) {
      return `Please choose a ${fieldName} with at least ${length} characters`;
    }
    return false;
  };
};

export const maxLength = (length, fieldName) => {
  return (value) => {
    if (isEmpty(value)) {
      return false;
    }
    if (value.toString().length > length) {
      return `${fieldName} must be less than ${length} characters`;
    }
    return false;
  };
};

export function confirmPassword() {
  return (value, data) => {
    if (data.password !== data.confirm_password) {
      return 'Your Password and Password Confirmation do not match.';
    }
    return false;
  };
}

export function confirmEmail() {
  return (value, data) => {
    if (data.email !== data.confirm_email) {
      return 'Your Email and Email Confirmation do not match.';
    }
    return false;
  };
}

export default {
  isEmpty,
  createValidator,
  match,
  required,
  arrayValidator,
  arrayMinLength,
  minLength,
  maxLength,
  confirmPassword,
};
