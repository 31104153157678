 
const paths = {
  homepage: '/',
  about: '/about',
  
  
};

 

const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

 

const config = {
 
  paths, 
  breakpoints,
  apis: {
    
    logIn: {
      url: '/login',
      method: 'POST',
    },
    logOut: {
      url: '/logout',
      method: 'GET',
    },
    signUp: {
      url: '/register',
      method: 'POST',
    },
    paymentIntent: {
      url: '/payment-intent',
      method: 'POST',
    },
    checkout: {
      url: '/checkout',
      method: 'POST',
    },
    checkoutCompleted: {
      url: '/checkout-completed',
      method: 'POST',
    },


    forgotPassword: {
      url: '/auth/forgot-password',
      method: 'POST',
    },
    confirmForgotPassword: {
      url: '/auth/reset-password',
      method: 'POST',
    },
    resetPassword: {
      url: (userId) => `/users/${userId}`,
      method: 'PUT',
    },
    
    requestVerificationEmail: {
      url: '/auth/send-email-confirmation',
      method: 'POST',
    },
    verifyEmail: {
      url: '/auth/email-confirmation',
      method: 'GET',
    },
    getProfile: {
      url: '/users/me',
      method: 'GET',
    },
     
  },
  
};

export default config;
