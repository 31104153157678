import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={66}
    height={66}
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42.845 46.2 33 39.875 23.155 46.2l2.97-11.33-9.047-7.37 11.687-.715L33 15.95l4.235 10.835 11.688.715-9.048 7.37M55 33a5.5 5.5 0 0 1 5.5-5.5v-11A5.5 5.5 0 0 0 55 11H11a5.5 5.5 0 0 0-5.5 5.5v11a5.5 5.5 0 0 1 0 11v11A5.5 5.5 0 0 0 11 55h44a5.5 5.5 0 0 0 5.5-5.5v-11A5.5 5.5 0 0 1 55 33Z"
      fill="#FD4F7A"
    />
  </svg>
);

export default SvgComponent;
