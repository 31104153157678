import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={60}
    height={60}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m10.888 11.111 7.082 9.346 9.156-9.346H10.888Zm22.178 0 9.034 9.221 6.877-9.221H33.066Zm-2.97.099-9.928 10.133h19.855L30.096 11.21Zm20.848.926-6.87 9.207h13.88l-7.01-9.207Zm-41.96.088-6.772 9.119h13.686l-6.915-9.119ZM2.127 23.533 26.55 50.299l-9.752-26.767H2.128Zm17 0 10.967 30.099 10.968-30.1H19.128Zm24.264 0-9.751 26.766 24.422-26.767h-14.67Z"
      fill="#FD4F7A"
    />
  </svg>
)

export default SvgComponent