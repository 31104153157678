import React, { useEffect } from 'react';
import { 
 // BrowserRouter as Router, 
  Routes, 
  Route, 
  //Link, 
  Outlet 
} from 'react-router-dom';
import Home from '@/pages/home';
//import About from '@/pages/about';
//import Dashboard from '@/pages/dashboard';

//import NavbarSpacer from '@/components/NavbarSpacer'
import Headroom from 'react-headroom';
import Navbar from '@/components/Navbar';
import NotFound from '@/components/NotFound';
import smoothscroll from 'smoothscroll-polyfill';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-WBP0C2P5VH');
} else {
  ReactGA.initialize('G-ZXZPMZW3YQ');
}

smoothscroll.polyfill();

function Layout() {
  return (
    <div>
      <Headroom disableInlineStyles>
        <Navbar />
      </Headroom>
      <Outlet />
    </div>
  );
}

/* function NavLayout() {
  return (
    <div>
       // <NavbarSpacer/>
     <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/dashboard">Dashboard</Link>
        </li>
      </ul>

      <hr />
      <Outlet /> 
    </div>
  )
} 
 */

function App() {
  const location = useLocation();

  useEffect(() => {
    //console.log("hash", location.hash);
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + (location.hash ? location.hash : '') + location.search,
    });
  }, [location]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {/*  <Route path="" element={<NavLayout />}>
                <Route path="about" element={<About />} />
                <Route path="dashboard" element={<Dashboard />} />
            </Route> */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
