import React from 'react';

//import ZoomIn from '@/components/ZoomIn';
import clsx from 'clsx';
import styles from './Hero.module.scss';

const Hero = () => {
  return (
    <div className={clsx(styles.hero_container)}>
      <div className={styles.hero_inner}>
        <div className={styles.colLeft}>
          <h2 className={styles.headline}>Welcome to the future of ticketing</h2>

          <p className={styles.text}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit
            interdum, ac aliquet odio mattis.
          </p>
        </div>
        <div className={styles.colRight}>
          <div>
           {/*  <img alt="" className={styles.colRightImage} src="https://via.placeholder.com/456" /> */}
            <img alt="" className={styles.colRightImage} src="/img/OWLGO_HEROIMAGE_OWL.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
