import React from 'react';

//import ZoomIn from '@/components/ZoomIn';
import clsx from 'clsx';
import styles from './Platform.module.scss';
import { Ticket, Blockchain, Diamond } from '@/components/Icons';

const platformItems = [
  {
    id: 'item1',
    title: (
      <>
        Blockchain
        <br />
        Technology
      </>
    ),
    icon: <Blockchain className={styles.platform_icon} />,
    text: 'We solve ticket scalping & fraud using blockchain technology that authenticates and secures the ticketing process',
  },
  {
    id: 'item2',
    title: (
      <>
        Smart
        <br />
        Ticket
      </>
    ),
    icon: <Ticket className={styles.platform_icon} />,
    text: 'We use Smart Tickets that can be personalised to your preferences',
  },
  {
    id: 'item3',
    title: (
      <>
        Exclusive
        <br />
        Benefits
      </>
    ),
    icon: <Diamond className={styles.platform_icon} />,
    text: 'Fans get tailored experiences while event organisers benefit from understanding fans better',
  },
];

const Platform = () => {
  return (
    <div className={clsx(styles.platform_container)}>
      <div className={styles.platform_inner}>
        <div className={styles.centerHeadline}>
          <h2 className={styles.headline}>
            The ticket platform
            <br />
            everyone loves
          </h2>
        </div>
        <div className={styles.platform_row}>
          {platformItems.map((item) => (
            <div key={item.id} className={styles.platform_col}>
              {item.icon}
              <div className={styles.platform_col_headline}>{item.title}</div>
              <div className={styles.platform_col_text}>{item.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Platform;
