import React from 'react';
import clsx from 'clsx';
import styles from './NotFound.module.scss';
import ActiveLink from '@/components/ActiveLink';

const NotFound = () => {
  return (
    <div className={clsx(styles.container)}>
      <div className={styles.inner}>
        <div className={styles.centerHeadline}>
          <h2 className={styles.headline}>
            404
            <br />
            Not found
          </h2>
          <p className={styles.text}>
            The page you were looking for doesn&apos;t exist. You may have mistyped the address or
            the page may have moved.<br />
            <ActiveLink activeClassName={'active'} to={'/'} className={styles.link}>
              Back to the home page
            </ActiveLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
