import React from 'react';
import styles from './FormInputField.module.scss';
import clsx from 'clsx';

const FormInputField = (props) => {
  const onChange = (e) => {
    //console.log(e)
    const regex = RegExp(props.pattern);
    if (e.target.value === '' || regex.test(e.target.value)) {
      props.input.onChange(e);
    }
  };

  const onBlur = (e) => {
    e.target.placeholder = props.placeholder;
    props.input.onBlur(e);
  };

  return (
    <div className={styles.inputWrapper}>
      <input
        className={clsx(styles.input, { [styles.error]: props.meta.error && props.meta.touched })}
        {...props.input}
        onChange={props.pattern ? onChange : props.input.onChange}
        placeholder={props.placeholder}
        onFocus={(e) => (e.target.placeholder = '')}
        onBlur={onBlur}
        type={props.inputType ? props.inputType : 'text'}
        pattern={props.pattern}
      />
      {props.meta.error && props.meta.touched && (
        <div className={styles.fieldError}>{props.meta.error}</div>
      )}
    </div>
  );
};

export default FormInputField;
