import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={66}
    height={66}
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={4}
      y={2}
      width={58}
      height={59}
    >
      <path
        d="M55 41.25V20.625l-17.188-9.668m-9.624 0L11 20.625V41.25m4.125 6.446L33 57.75l11-6.188 6.875-3.867"
        stroke="#fff"
        strokeWidth={5.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m28.875 25.781-4.125 2.407v9.625l4.125 2.406L33 42.625l4.125-2.406 4.125-2.407v-9.624l-4.125-2.407L33 23.375l-4.125 2.406Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={5.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 23.375V13.75m8.25 23.375 9.625 5.5m-26.125-5.5-9.625 5.5"
        stroke="#fff"
        strokeWidth={5.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 13.75a4.125 4.125 0 1 0 0-8.25 4.125 4.125 0 0 0 0 8.25ZM11 49.5a4.125 4.125 0 1 0 0-8.25 4.125 4.125 0 0 0 0 8.25ZM55 49.5a4.125 4.125 0 1 0 0-8.25 4.125 4.125 0 0 0 0 8.25Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={5.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#a)">
      <path d="M0 0h66v66H0V0Z" fill="#FD4F7A" />
    </g>
  </svg>
);

export default SvgComponent;
