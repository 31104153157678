import React from 'react';
import BaseModal, { ModalTypes } from '@/components/BaseModal';
import styles from './FormSuccessModal.module.scss';

//import { Send} from '@/components/Icons'

const FormSuccessModal = (props) => {
  return (
    <BaseModal
      {...props}
      shouldCloseOnOverlayClick={true}
      showCloseButton={true}
      needScollbar={false}
      modalType={ModalTypes.Small}
    >
      <div>
        <div className={styles.headline}>Thank you for the message!</div>
        <div className={styles.text}>
          We have received your message.<span className={styles.icon}>{/* <Send/> */}</span>
        </div>
      </div>
    </BaseModal>
  );
};

export default FormSuccessModal;
