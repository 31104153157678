//import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useMatch, useResolvedPath, useLocation } from 'react-router-dom';
import { HashLink as NavLink } from 'react-router-hash-link';
import clsx from 'clsx';

const navbarHeight = 75;

const ActiveLink = ({ children, to, className, activeClassName, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  let location = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    //console.log("resolved match",resolved,match,  resolved.hash, location.hash, match && resolved.hash === location.hash);
    setIsActive(match && resolved.hash === location.hash);
  }, [resolved, match, location, isActive]);

  const scrollWithOffset = (el, yOffset) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({ top: yCoordinate - yOffset, behavior: 'smooth' });
  };

  return (
    <NavLink
      smooth
      className={clsx(className, { [activeClassName]: isActive })}
      to={to}
      {...props}
      scroll={(el) => scrollWithOffset(el, navbarHeight)}
    >
      {children}
    </NavLink>
  );
};

export default ActiveLink;
