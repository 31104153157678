import React, { useState, useEffect, useCallback } from 'react';
import styles from './Contactform.module.scss';
import axios from 'axios';
import { createValidator, required, maxLength, isValidEmail } from '@/helpers/formValidation';
import clsx from 'clsx';
import { Form, Field } from 'react-final-form';
import FormInputField from '@/components/Form/FormInputField';
import FormInputFieldEmail from '@/components/Form/FormInputFieldEmail';
import FormTextAreaField from '@/components/Form/FormTextAreaField';
import FormSuccessModal from '@/components/FormSuccessModal';
import FormErrorModal from '@/components/FormErrorModal';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FORM_ERROR } from 'final-form';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const isFormTest = false;

const Contactform = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);

  const [token, setToken] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  const closeErrorModal = () => {
    setIsErrorOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };
  const openErrorModal = () => {
    setIsErrorOpen(true);
  };
  useEffect(() => {
    let timer;
    if (isOpen) {
      // console.log("isopen");
      timer = setTimeout(() => {
        //console.log("Timeout");
        setIsOpen(false);
      }, 5000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isOpen]);

  const onSubmit = async (values) => {
    const api_baseurl = process.env.REACT_APP_API_URL;
    //const api_baseurl ='http://localhost:8888/api/';
    if (isFormTest) {
      await sleep(300);
      window.alert(JSON.stringify(values, 0, 2));
      openModal();
    } else {
      if (!token) {
        openErrorModal();
        return Promise.resolve({
          [FORM_ERROR]: 'Submission Failed',
        });
      }
      return axios
        .post(api_baseurl + 'form', values, {
          headers: {
            'x-api-token': process.env.REACT_APP_X_API_TOKEN,
          },
        })
        .then((res) => {
          openModal();
          //console.log("form response ", res);
          return res;
        })
        .catch(function () {
          openErrorModal();
          return {
            [FORM_ERROR]: 'Submission Failed',
          };
        });
    }
  };

  const handleVerify = useCallback(async (token) => {
    //console.log("call set token", token);
    setToken(token);
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      validate={createValidator({
        name: [required('Please enter your name'), maxLength(255, 'Name')],
        email: [
          required('Please enter a valid email'),
          isValidEmail('Please enter a valid email address'),
          maxLength(255, 'Email'),
        ],
        message: [required('Please enter message'), maxLength(10000, 'Message')],
      })}
      render={({ handleSubmit /* submitError */ }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.formcontainer}>
            <GoogleReCaptcha onVerify={handleVerify} />

            <div className={styles.inputgroup}>
              <Field name="name" placeholder="Name" component={FormInputField} />
            </div>
            <div className={styles.inputgroup}>
              <Field name="email" placeholder="Email" component={FormInputFieldEmail} />
            </div>
            <div className={styles.inputgroup}>
              <Field name="message" placeholder="Message" component={FormTextAreaField} />
            </div>

            <button className={clsx(styles.btn, styles.btnSubmit)} type="submit">
              <span className={styles.btnSubmitText}>Send</span>
            </button>
          </div>
          {/*  {submitError && <div className={styles.submissionError}>{submitError}</div>} */}
          <FormSuccessModal isOpen={isOpen} closeModal={closeModal} />
          <FormErrorModal isOpen={isErrorOpen} closeModal={closeErrorModal} />
        </form>
      )}
    />
  );
};

export default Contactform;
