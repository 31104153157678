import React from 'react';

//import ZoomIn from '@/components/ZoomIn';
import clsx from 'clsx';
import styles from './Contact.module.scss';
import Contactform from '@/components/Contactform';

import { Discord, Twitter, Toutube } from '@/components/Icons';

const Contact = () => {
  return (
    <div className={clsx(styles.contact_container)}>
      <div className={styles.contact_inner}>
        <div className={styles.centerHeadline}>
          <h2 className={styles.headline}>
            Don’t be
            <br />a stranger!
            <br />
            Let’s connect
          </h2>
          <div className={styles.contact_text_mobile_top}> Drop us a message!</div>
        </div>
        <div className={styles.contact_row}>
          <div className={styles.contact_col_left}>
            <div className={styles.contact_text}>
              Drop us
              <br />a message!
              <br />
              <br />
              Join us on our
              <br />
              social media
              <br />
              platform(s)!
            </div>
            <div className={styles.contact_text_mobile_bottom}>
              Join us on our social
              <br />
              media platform(s)!
            </div>
            <div className={styles.social_links}>
              <a
                className={styles.social_link}
                href="https://discord.com"
                rel="noreferrer"
                target="_blank"
              >
                <Discord className={styles.social_icon} />
              </a>
              <a
                className={styles.social_link}
                href="https://twitter.com/"
                rel="noreferrer"
                target="_blank"
              >
                <Twitter className={styles.social_icon} />
              </a>
              <a
                className={styles.social_link}
                href="https://www.youtube.com/"
                rel="noreferrer"
                target="_blank"
              >
                <Toutube className={styles.social_icon} />
              </a>
            </div>
          </div>
          <div className={styles.contact_col_right}>
            <Contactform />
          </div>
        </div>
        <div className={styles.copyright_text}>©️Owlgo.io, All rights reserved</div>
      </div>
    </div>
  );
};

export default Contact;
