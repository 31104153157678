import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#rrr)">
      <path
        d="M16 3.68C.275 3.68 0 5.078 0 16 0 26.92.275 28.32 16 28.32S32 26.92 32 16c0-10.922-.275-12.32-16-12.32Zm5.128 12.854-7.184 3.354c-.629.29-1.144-.036-1.144-.73v-6.317c0-.692.515-1.02 1.144-.73l7.184 3.354c.629.295.629.775 0 1.07Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="rrr">
        <path fill="currentColor" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
