import React, { useState } from 'react';

//import ZoomIn from '@/components/ZoomIn';
import clsx from 'clsx';
import styles from './Experience.module.scss';
import { Star, Entertain, Diamond } from '@/components/Icons';
import YoutubeEmbed from '@/components/YoutubeEmbed';
//import ScaleUpIn from '@/components/ScaleUpIn';

const showVideo = false;

const experienceItems = [
  {
    id: 'item1',
    title: (
      <>
        Embrace
        <br />
        Ownership
      </>
    ),
    icon: <Star className={styles.platform_icon} />,
    text: 'Our Smart Tickets come with NFTs capabilities that extend the experience beyond the event.',
  },
  {
    id: 'item2',
    title: (
      <>
        Engage
        <br />
        Creators
      </>
    ),
    icon: <Entertain className={styles.platform_icon} />,
    text: 'NFTs are digital collectibles that allow fans to engage with content creators.',
  },
  {
    id: 'item3',
    title: (
      <>
        Social
        <br />
        Club
      </>
    ),
    icon: <Diamond className={styles.platform_icon} />,
    text: 'Your NFTs can also unlock unique benefits that provide exclusive access to future events.',
  },
];

const Experience = () => {
  const [youTubeShow, setyouTubeShow] = useState(false);
  return (
    <div className={clsx(styles.experience_container)}>
      <div className={styles.experience_inner}>
        {/* <ScaleUpIn> */}
        <div className={styles.centerHeadline}>
          <h2 className={styles.headline}>Immerse yourself in a whole new experience</h2>
        </div>
        {showVideo && (
          <div className={styles.videoWrapper}>
            <div
              className={clsx(styles.ytWrapper, {
                [styles.ytShow]: youTubeShow,
              })}
            >
              <YoutubeEmbed embedId="rokGy0huYEA" play={youTubeShow} />
            </div>
            {!youTubeShow ? (
              <div className={styles.videoImage} onClick={() => setyouTubeShow(true)}>
                <img src="/img/video-placeholder.png" />
              </div>
            ) : null}
          </div>
        )}
        <div className={styles.experience_row}>
          {experienceItems.map((item) => (
            <div key={item.id} className={styles.experience_col}>
              {item.icon}
              <div className={styles.experience_col_headline}>{item.title}</div>
              <div className={styles.experience_col_text}>{item.text}</div>
            </div>
          ))}
        </div>
        {/* </ScaleUpIn> */}
      </div>
    </div>
  );
};

export default Experience;
