import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.524 6.582c-1.114.494-2.31.827-3.567.978a6.227 6.227 0 0 0 2.73-3.438 12.4 12.4 0 0 1-3.943 1.507 6.213 6.213 0 0 0-10.584 5.667 17.64 17.64 0 0 1-12.803-6.49 6.208 6.208 0 0 0-.84 3.122 6.213 6.213 0 0 0 2.763 5.17 6.194 6.194 0 0 1-2.814-.777v.08a6.214 6.214 0 0 0 4.983 6.09 6.255 6.255 0 0 1-2.805.107 6.215 6.215 0 0 0 5.802 4.312 12.463 12.463 0 0 1-7.714 2.66c-.496 0-.99-.028-1.483-.086a17.567 17.567 0 0 0 9.52 2.79c11.427 0 17.673-9.464 17.673-17.672 0-.266-.006-.536-.018-.802a12.627 12.627 0 0 0 3.097-3.214l.003-.004Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent 
