import React from 'react';
import BaseModal, { ModalTypes } from '@/components/BaseModal';
import styles from './FormErrorModal.module.scss';

//import { AlertCircle} from '@/components/Icons'

const FormErrorModal = (props) => {
  return (
    <BaseModal
      {...props}
      shouldCloseOnOverlayClick={true}
      showCloseButton={true}
      needScollbar={false}
      modalType={ModalTypes.Small}
    >
      <div>
        <div className={styles.headline}>Form submission Failed!!</div>
        <div className={styles.text}>
          <span className={styles.icon}>{/* <AlertCircle/> */}</span>
        </div>
      </div>
    </BaseModal>
  );
};

export default FormErrorModal;
