import React from 'react';
//import { Link } from 'react-router-dom';
import ActiveLink from '@/components/ActiveLink';
import clsx from 'clsx';

import config from '@/helpers/config';
//import { disableScroll, enableScroll } from '@/helpers/behaviours';

import useToggle from '@/hooks/useToggle';
//import useWindowSize from '@/hooks/useWindowSize';

//import { Menu as MenuIcon, Discord, Facebook, Twitter } from '@/components/Icons';

//import DesktopNav from '@/components/Menu/DesktopNav';
//import SideMenu from '@/components/Menu/SideMenu';
//import { useScroll } from 'framer-motion';

import styles from './Navbar.module.scss';

//const menuBreakpoint = 1152;

const Navbar = ({ children }) => {
  const toggleMenu = useToggle();
  //const windowSize = useWindowSize();
  //const toggleProfile = useToggle();

  //const [bgBlack, setBgBlack] = useState(false);
  const bgBlack = false;

  //const { scrollY } = useScroll();
  /*   useEffect(() => {
    return scrollY.onChange((latest) => {
      console.log("Page scroll: ", latest);
      if (latest > windowSize.height) {
        setBgBlack(true);
      } else {
        setBgBlack(false);
      }
    });
  }, [scrollY, windowSize]); */

  /* const handleOpenMenu = useCallback(() => {
    toggleMenu.setActive();
    disableScroll();
  }, []);

  const toggleOpenMenu = useCallback(() => {
    if (toggleMenu.active) {
      console.log('click active');
      toggleMenu.setInActive();
      enableScroll();
    } else {
      console.log('click inactive');
      toggleMenu.setActive();
      disableScroll();
    }
  }, [toggleMenu.active]);

  const handleCloseMenu = useCallback(() => {
    toggleMenu.setInActive();
    enableScroll();
  }, []); */

  return (
    <>
      <header className={clsx(styles.container, { [styles.black]: bgBlack })}>
        <div className={styles.inner}>
          <ActiveLink to={config.paths.homepage} activeClassName={'activ'} className={styles.link}>
            <div className={styles.brandtext}>
              <img className={styles.brandImage} src="/img/logo.png" />
            </div>
          </ActiveLink>
          <div className={styles.navigation}>
            {/*  {windowSize.width > menuBreakpoint ? <DesktopNav /> : null} */}

            {/* {windowSize.width <= menuBreakpoint ? (
            <button
              type="button"
              className={styles.navigationItem}
              aria-label="Open the menu"
              onClick={() => {
                //toggleProfile.toggle(false);
                toggleOpenMenu();
              }}
            >
              <MenuIcon />
            </button>
          ) : null} */}
          </div>

          {/* <SideMenu isOpen={toggleMenu.active} onClose={handleCloseMenu} /> */}
        </div>
      </header>
      {children &&
        children({
          toggleMenu: toggleMenu.toggle,
        })}
    </>
  );
};

export default Navbar;
