import React from 'react';
import clsx from 'clsx';

import styles from './FormTextAreaField.module.scss';

const FormTextAreaField = (props) => {
  const onBlur = (e) => {
    e.target.placeholder = props.placeholder;
    props.input.onBlur(e);
  };

  return (
    <div className={styles.inputWrapper}>
      <textarea
        className={clsx(styles.input, {
          [styles.error]: props.meta.error && props.meta.touched,
        })}
        {...props.input}
        placeholder={props.placeholder}
        onFocus={(e) => (e.target.placeholder = '')}
        onBlur={onBlur}
        type={'text'}
      />
      {props.meta.error && props.meta.touched && (
        <div className={styles.fieldError}>{props.meta.error}</div>
      )}
    </div>
  );
};

export default FormTextAreaField;
