import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M27.089 5.99c-2.04-.92-4.227-1.6-6.513-1.987a.1.1 0 0 0-.106.048c-.28.492-.592 1.133-.81 1.64a24.755 24.755 0 0 0-7.316 0 16.482 16.482 0 0 0-.823-1.64.102.102 0 0 0-.105-.05 26.64 26.64 0 0 0-6.514 1.987.093.093 0 0 0-.042.036C.71 12.124-.427 18.074.132 23.948a.106.106 0 0 0 .041.074 26.707 26.707 0 0 0 7.99 3.973.104.104 0 0 0 .113-.035c.617-.825 1.164-1.7 1.634-2.617a.1.1 0 0 0-.054-.139 17.604 17.604 0 0 1-2.496-1.17.1.1 0 0 1-.042-.129.099.099 0 0 1 .031-.038c.168-.124.336-.253.496-.383a.1.1 0 0 1 .104-.013c5.236 2.352 10.907 2.352 16.081 0a.1.1 0 0 1 .106.012c.16.13.326.26.496.384a.099.099 0 0 1 .04.085.1.1 0 0 1-.048.082c-.798.458-1.627.846-2.498 1.169a.1.1 0 0 0-.054.14c.48.916 1.029 1.788 1.633 2.616a.104.104 0 0 0 .112.037 26.618 26.618 0 0 0 8.003-3.974.1.1 0 0 0 .042-.072c.667-6.792-1.117-12.694-4.732-17.923a.08.08 0 0 0-.041-.037ZM10.693 20.37c-1.576 0-2.876-1.425-2.876-3.173 0-1.75 1.275-3.174 2.876-3.174 1.613 0 2.901 1.436 2.876 3.174 0 1.749-1.275 3.173-2.876 3.173Zm10.633 0c-1.577 0-2.876-1.425-2.876-3.173 0-1.75 1.274-3.174 2.876-3.174 1.614 0 2.902 1.436 2.876 3.174 0 1.749-1.261 3.173-2.876 3.173Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
